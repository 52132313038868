import { __read } from "tslib";
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { LOG_TYPE } from '@aws-amplify/core/lib/Logger';
import { Event } from './Event';
import { NetRequest } from '../network/NetRequest';
import { SendMode } from '../types';
import { StorageUtil } from '../util/StorageUtil';
var logger = new Logger('EventRecorder');
var EventRecorder = /** @class */ (function () {
    function EventRecorder(context) {
        this.context = context;
        this.bundleSequenceId = StorageUtil.getBundleSequenceId();
    }
    EventRecorder.prototype.record = function (event, isImmediate) {
        if (isImmediate === void 0) { isImmediate = false; }
        if (this.context.configuration.isLogEvents) {
            logger.level = LOG_TYPE.DEBUG;
            logger.debug("Logged event ".concat(event.event_type, "\n"), event);
        }
        var currentMode = this.context.configuration.sendMode;
        if (currentMode === SendMode.Immediate || isImmediate) {
            this.sendEventImmediate(event);
        }
        else {
            if (!StorageUtil.saveEvent(event)) {
                this.sendEventImmediate(event);
            }
        }
    };
    EventRecorder.prototype.sendEventImmediate = function (event) {
        var _this = this;
        var eventsJson = JSON.stringify([event]);
        NetRequest.sendRequest(eventsJson, this.context, this.bundleSequenceId).then(function (result) {
            if (result) {
                logger.debug('Event send success');
                if (_this.haveFailedEvents) {
                    _this.sendFailedEvents();
                }
            }
            else {
                StorageUtil.saveFailedEvent(event);
                _this.haveFailedEvents = true;
            }
        });
        this.plusSequenceId();
    };
    EventRecorder.prototype.sendFailedEvents = function () {
        var _this = this;
        if (this.isSendingFailedEvents)
            return;
        this.isSendingFailedEvents = true;
        var failedEvents = StorageUtil.getFailedEvents();
        if (failedEvents.length > 0) {
            var eventsJson = failedEvents + Event.Constants.SUFFIX;
            NetRequest.sendRequest(eventsJson, this.context, this.bundleSequenceId).then(function (result) {
                if (result) {
                    logger.debug('Failed events send success');
                    StorageUtil.clearFailedEvents();
                    _this.haveFailedEvents = false;
                }
                _this.isSendingFailedEvents = false;
            });
            this.plusSequenceId();
        }
    };
    EventRecorder.prototype.flushEvents = function () {
        var _this = this;
        if (this.isFlushingEvents) {
            return;
        }
        var _a = __read(this.getBatchEvents(), 2), eventsJson = _a[0], needsFlushTwice = _a[1];
        if (eventsJson === '') {
            return;
        }
        this.isFlushingEvents = true;
        NetRequest.sendRequest(eventsJson, this.context, this.bundleSequenceId, NetRequest.BATCH_REQUEST_RETRY_TIMES, NetRequest.BATCH_REQUEST_TIMEOUT).then(function (result) {
            if (result) {
                StorageUtil.clearEvents(eventsJson);
            }
            _this.isFlushingEvents = false;
            if (result && needsFlushTwice) {
                _this.flushEvents();
            }
        });
        this.plusSequenceId();
    };
    EventRecorder.prototype.getBatchEvents = function () {
        var allEventsStr = StorageUtil.getAllEvents();
        if (allEventsStr === '') {
            return [allEventsStr, false];
        }
        else if (allEventsStr.length <= StorageUtil.MAX_REQUEST_EVENTS_SIZE) {
            return [allEventsStr + Event.Constants.SUFFIX, false];
        }
        else {
            var isOnlyOneEvent = allEventsStr.lastIndexOf(Event.Constants.LAST_EVENT_IDENTIFIER) < 0;
            var firstEventSize = allEventsStr.indexOf(Event.Constants.LAST_EVENT_IDENTIFIER);
            if (isOnlyOneEvent) {
                return [allEventsStr + Event.Constants.SUFFIX, false];
            }
            else if (firstEventSize > StorageUtil.MAX_REQUEST_EVENTS_SIZE) {
                allEventsStr = allEventsStr.substring(0, firstEventSize + 1);
                return [allEventsStr + Event.Constants.SUFFIX, true];
            }
            else {
                allEventsStr = allEventsStr.substring(0, StorageUtil.MAX_REQUEST_EVENTS_SIZE);
                var endIndex = allEventsStr.lastIndexOf(Event.Constants.LAST_EVENT_IDENTIFIER);
                return [
                    allEventsStr.substring(0, endIndex + 1) + Event.Constants.SUFFIX,
                    true,
                ];
            }
        }
    };
    EventRecorder.prototype.plusSequenceId = function () {
        this.bundleSequenceId += 1;
        StorageUtil.saveBundleSequenceId(this.bundleSequenceId);
    };
    EventRecorder.prototype.sendEventsInBackground = function (isWindowClosing) {
        if (this.haveFailedEvents &&
            this.getFailedEventsLength() < NetRequest.KEEP_ALIVE_SIZE_LIMIT) {
            this.sendFailedEvents();
            if (isWindowClosing) {
                StorageUtil.clearFailedEvents();
            }
        }
        if (this.context.configuration.sendMode === SendMode.Batch) {
            var eventLength = this.getEventsLength();
            if (eventLength > 0 && eventLength < NetRequest.KEEP_ALIVE_SIZE_LIMIT) {
                this.flushEvents();
                if (isWindowClosing) {
                    StorageUtil.clearAllEvents();
                }
            }
        }
    };
    EventRecorder.prototype.getFailedEventsLength = function () {
        var failedEvents = StorageUtil.getFailedEvents();
        return new Blob([failedEvents]).size;
    };
    EventRecorder.prototype.getEventsLength = function () {
        var events = StorageUtil.getAllEvents();
        return new Blob([events]).size;
    };
    return EventRecorder;
}());
export { EventRecorder };
