/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { __extends, __values } from "tslib";
import { BaseTracker } from './BaseTracker';
import { BrowserInfo } from '../browser';
import { Event } from '../provider';
import { PageType } from '../types';
import { MethodEmbed } from '../util/MethodEmbed';
import { StorageUtil } from '../util/StorageUtil';
var PageViewTracker = /** @class */ (function (_super) {
    __extends(PageViewTracker, _super);
    function PageViewTracker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isEntrances = false;
        _this.searchKeywords = Event.Constants.KEYWORDS;
        _this.lastEngageTime = 0;
        _this.lastScreenStartTimestamp = 0;
        _this.isFirstTime = true;
        return _this;
    }
    PageViewTracker.prototype.init = function () {
        PageViewTracker.lastActiveTimestamp = new Date().getTime();
        PageViewTracker.idleTimeoutDuration =
            this.provider.configuration.idleTimeoutDuration;
        var configuredSearchKeywords = this.provider.configuration.searchKeyWords;
        Object.assign(this.searchKeywords, configuredSearchKeywords);
        this.onPageChange = this.onPageChange.bind(this);
        if (this.isMultiPageApp()) {
            if (!BrowserInfo.isFromReload()) {
                this.onPageChange();
            }
        }
        else {
            this.trackPageViewForSPA();
        }
        this.isFirstTime = false;
    };
    PageViewTracker.prototype.trackPageViewForSPA = function () {
        MethodEmbed.add(history, 'pushState', this.onPageChange);
        MethodEmbed.add(history, 'replaceState', this.onPageChange);
        window.addEventListener('popstate', this.onPageChange);
        if (!BrowserInfo.isFromReload()) {
            this.onPageChange();
        }
    };
    PageViewTracker.prototype.onPageChange = function () {
        var _a;
        PageViewTracker.updateIdleDuration();
        if (this.context.configuration.isTrackPageViewEvents) {
            var previousPageUrl = StorageUtil.getPreviousPageUrl();
            var previousPageTitle = StorageUtil.getPreviousPageTitle();
            var currentPageUrl = BrowserInfo.getCurrentPageUrl();
            var currentPageTitle = BrowserInfo.getCurrentPageTitle();
            if (this.isFirstTime ||
                this.isMultiPageApp() ||
                previousPageUrl !== currentPageUrl ||
                previousPageTitle !== currentPageTitle) {
                (_a = this.provider.scrollTracker) === null || _a === void 0 ? void 0 : _a.enterNewPage();
                if (!this.isMultiPageApp() &&
                    !this.isFirstTime &&
                    previousPageUrl !== '') {
                    this.recordUserEngagement();
                }
                this.trackPageView(previousPageUrl, previousPageTitle);
                this.trackSearchEvents();
                StorageUtil.savePreviousPageUrl(currentPageUrl);
                StorageUtil.savePreviousPageTitle(currentPageTitle);
            }
        }
    };
    PageViewTracker.prototype.trackPageView = function (previousPageUrl, previousPageTitle) {
        var _a;
        var previousPageStartTime = StorageUtil.getPreviousPageStartTime();
        var analyticsEvent = this.provider.createEvent({
            name: Event.PresetEvent.PAGE_VIEW,
        });
        var currentPageStartTime = analyticsEvent.timestamp;
        var eventAttributes = (_a = {},
            _a[Event.ReservedAttribute.PAGE_REFERRER] = previousPageUrl,
            _a[Event.ReservedAttribute.PAGE_REFERRER_TITLE] = previousPageTitle,
            _a[Event.ReservedAttribute.ENTRANCES] = this.isEntrances ? 1 : 0,
            _a);
        if (previousPageStartTime > 0) {
            eventAttributes[Event.ReservedAttribute.PREVIOUS_TIMESTAMP] =
                previousPageStartTime;
        }
        if (this.lastEngageTime > 0) {
            eventAttributes[Event.ReservedAttribute.ENGAGEMENT_TIMESTAMP] =
                this.lastEngageTime;
        }
        Object.assign(analyticsEvent.attributes, eventAttributes);
        this.provider.recordEvent(analyticsEvent);
        this.isEntrances = false;
        StorageUtil.savePreviousPageStartTime(currentPageStartTime);
        this.lastScreenStartTimestamp = currentPageStartTime;
    };
    PageViewTracker.prototype.setIsEntrances = function () {
        this.isEntrances = true;
    };
    PageViewTracker.prototype.updateLastScreenStartTimestamp = function () {
        this.lastScreenStartTimestamp = new Date().getTime();
        PageViewTracker.idleDuration = 0;
        PageViewTracker.lastActiveTimestamp = this.lastScreenStartTimestamp;
    };
    PageViewTracker.prototype.recordUserEngagement = function (isImmediate) {
        var _a;
        if (isImmediate === void 0) { isImmediate = false; }
        this.lastEngageTime = this.getLastEngageTime();
        if (this.provider.configuration.isTrackUserEngagementEvents &&
            this.lastEngageTime > Constants.minEngagementTime) {
            this.provider.record({
                name: Event.PresetEvent.USER_ENGAGEMENT,
                attributes: (_a = {},
                    _a[Event.ReservedAttribute.ENGAGEMENT_TIMESTAMP] = this.lastEngageTime,
                    _a),
                isImmediate: isImmediate,
            });
        }
    };
    PageViewTracker.prototype.getLastEngageTime = function () {
        var duration = new Date().getTime() - this.lastScreenStartTimestamp;
        var engageTime = duration - PageViewTracker.idleDuration;
        PageViewTracker.idleDuration = 0;
        return engageTime;
    };
    PageViewTracker.prototype.isMultiPageApp = function () {
        return this.context.configuration.pageType === PageType.multiPageApp;
    };
    PageViewTracker.prototype.trackSearchEvents = function () {
        var e_1, _a, _b;
        if (!this.context.configuration.isTrackSearchEvents)
            return;
        var searchStr = window.location.search;
        if (!searchStr || searchStr.length === 0)
            return;
        var urlParams = new URLSearchParams(searchStr);
        try {
            for (var _c = __values(this.searchKeywords), _d = _c.next(); !_d.done; _d = _c.next()) {
                var keyword = _d.value;
                if (urlParams.has(keyword)) {
                    var searchTerm = urlParams.get(keyword);
                    this.provider.record({
                        name: Event.PresetEvent.SEARCH,
                        attributes: (_b = {},
                            _b[Event.ReservedAttribute.SEARCH_KEY] = keyword,
                            _b[Event.ReservedAttribute.SEARCH_TERM] = searchTerm,
                            _b),
                    });
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    PageViewTracker.updateIdleDuration = function () {
        var currentTimestamp = new Date().getTime();
        var idleDuration = currentTimestamp - PageViewTracker.lastActiveTimestamp;
        if (idleDuration > PageViewTracker.idleTimeoutDuration) {
            PageViewTracker.idleDuration += idleDuration;
        }
        PageViewTracker.lastActiveTimestamp = currentTimestamp;
    };
    PageViewTracker.lastActiveTimestamp = 0;
    PageViewTracker.idleDuration = 0;
    PageViewTracker.idleTimeoutDuration = 0;
    return PageViewTracker;
}(BaseTracker));
export { PageViewTracker };
export var Constants;
(function (Constants) {
    Constants[Constants["minEngagementTime"] = 1000] = "minEngagementTime";
})(Constants || (Constants = {}));
