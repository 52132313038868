/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
export var SendMode;
(function (SendMode) {
    SendMode["Immediate"] = "Immediate";
    SendMode["Batch"] = "Batch";
})(SendMode || (SendMode = {}));
export var PageType;
(function (PageType) {
    PageType["SPA"] = "SPA";
    PageType["multiPageApp"] = "multiPageApp";
})(PageType || (PageType = {}));
export var Attr;
(function (Attr) {
    Attr["TRAFFIC_SOURCE_SOURCE"] = "_traffic_source_source";
    Attr["TRAFFIC_SOURCE_MEDIUM"] = "_traffic_source_medium";
    Attr["TRAFFIC_SOURCE_CAMPAIGN"] = "_traffic_source_campaign";
    Attr["TRAFFIC_SOURCE_CAMPAIGN_ID"] = "_traffic_source_campaign_id";
    Attr["TRAFFIC_SOURCE_TERM"] = "_traffic_source_term";
    Attr["TRAFFIC_SOURCE_CONTENT"] = "_traffic_source_content";
    Attr["TRAFFIC_SOURCE_CLID"] = "_traffic_source_clid";
    Attr["TRAFFIC_SOURCE_CLID_PLATFORM"] = "_traffic_source_clid_platform";
    Attr["VALUE"] = "_value";
    Attr["CURRENCY"] = "_currency";
})(Attr || (Attr = {}));
