import { Logger } from '@aws-amplify/core';
import { StorageUtil } from '../util/StorageUtil';
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
var logger = new Logger('BrowserInfo');
var BrowserInfo = /** @class */ (function () {
    function BrowserInfo() {
        if (!BrowserInfo.isBrowser())
            return;
        var _a = window.navigator, product = _a.product, vendor = _a.vendor, userAgent = _a.userAgent, language = _a.language;
        this.locale = language;
        this.initLocalInfo(language);
        this.make = product || vendor;
        this.userAgent = userAgent;
        this.zoneOffset = -new Date().getTimezoneOffset() * 60000;
        this.hostName = window.location.hostname;
        this.latestReferrer = window.document.referrer;
        if (this.latestReferrer && this.latestReferrer !== '') {
            try {
                var url = new URL(this.latestReferrer);
                this.latestReferrerHost = url.host;
            }
            catch (error) {
                logger.warn('parse latest referrer domain failed: ' + error);
            }
        }
    }
    BrowserInfo.prototype.initLocalInfo = function (locale) {
        if (locale.indexOf('-') > 0) {
            this.system_language = locale.split('-')[0];
            this.country_code = locale.split('-')[1].toUpperCase();
        }
        else {
            this.system_language = locale;
            this.country_code = '';
        }
    };
    BrowserInfo.isBrowser = function () {
        return (typeof window !== 'undefined' && typeof window.document !== 'undefined');
    };
    BrowserInfo.isFirefox = function () {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    };
    BrowserInfo.isNetworkOnLine = function () {
        return navigator.onLine;
    };
    BrowserInfo.getCurrentPageUrl = function () {
        if (!BrowserInfo.isBrowser())
            return '';
        else
            return window.location.href;
    };
    BrowserInfo.getCurrentPageTitle = function () {
        var _a;
        if (!BrowserInfo.isBrowser())
            return '';
        return (_a = window.document.title) !== null && _a !== void 0 ? _a : '';
    };
    BrowserInfo.isFromReload = function () {
        if (performance && performance.getEntriesByType) {
            var performanceEntries = performance.getEntriesByType('navigation');
            if (performanceEntries && performanceEntries.length > 0) {
                var type = performanceEntries[0]['type'];
                return type === 'reload' && StorageUtil.getPreviousPageUrl() !== '';
            }
        }
        else {
            logger.warn('unsupported web environment for performance');
        }
        return false;
    };
    return BrowserInfo;
}());
export { BrowserInfo };
