/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { __extends, __values } from "tslib";
import { Logger } from '@aws-amplify/core';
import { BaseTracker } from './BaseTracker';
import { PageViewTracker } from './PageViewTracker';
import { Event } from '../provider';
var logger = new Logger('ClickTracker');
var ClickTracker = /** @class */ (function (_super) {
    __extends(ClickTracker, _super);
    function ClickTracker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.processedElements = new WeakSet();
        return _this;
    }
    ClickTracker.prototype.init = function () {
        this.trackClick = this.trackClick.bind(this);
        this.trackDocumentClick = this.trackDocumentClick.bind(this);
        document.addEventListener('click', this.trackDocumentClick);
        var currentDomain = window.location.host;
        var domainList = this.context.configuration.domainList;
        if (!domainList.includes(currentDomain)) {
            domainList.push(currentDomain);
        }
        this.addClickListenerForATag();
    };
    ClickTracker.prototype.trackDocumentClick = function (event) {
        PageViewTracker.updateIdleDuration();
        if (!this.context.configuration.isTrackClickEvents)
            return;
        var targetElement = event.target;
        var element = this.findATag(targetElement);
        if (!element || this.processedElements.has(element))
            return;
        this.trackClick(event, element);
    };
    ClickTracker.prototype.trackClick = function (event, documentElement) {
        var _a;
        if (documentElement === void 0) { documentElement = undefined; }
        if (!this.context.configuration.isTrackClickEvents)
            return;
        var element = documentElement;
        if (!element) {
            var targetElement = event.target;
            element = this.findATag(targetElement);
        }
        if (element !== null) {
            var linkUrl = element.getAttribute('href');
            if (linkUrl === null || linkUrl.length === 0)
                return;
            var linkDomain = '';
            try {
                var url = new URL(linkUrl);
                linkDomain = url.host;
            }
            catch (error) {
                logger.debug('parse link domain failed: ' + error);
            }
            if (linkDomain === '')
                return;
            var linkClasses = element.getAttribute('class');
            var linkId = element.getAttribute('id');
            var outbound = !this.context.configuration.domainList.includes(linkDomain);
            this.provider.record({
                name: Event.PresetEvent.CLICK,
                attributes: (_a = {},
                    _a[Event.ReservedAttribute.LINK_URL] = linkUrl,
                    _a[Event.ReservedAttribute.LINK_DOMAIN] = linkDomain,
                    _a[Event.ReservedAttribute.LINK_CLASSES] = linkClasses,
                    _a[Event.ReservedAttribute.LINK_ID] = linkId,
                    _a[Event.ReservedAttribute.OUTBOUND] = outbound,
                    _a),
            });
        }
    };
    ClickTracker.prototype.addClickListenerForATag = function () {
        var _this = this;
        var observer = new MutationObserver(function (mutationsList) {
            var e_1, _a;
            var _loop_1 = function (mutation) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    var target = mutation.target;
                    if (target instanceof Element) {
                        var aTags_1 = target.querySelectorAll('a');
                        aTags_1.forEach(function (aTag) {
                            if (!_this.processedElements.has(aTags_1)) {
                                aTag.addEventListener('click', _this.trackClick);
                                _this.processedElements.add(aTag);
                            }
                        });
                    }
                }
            };
            try {
                for (var mutationsList_1 = __values(mutationsList), mutationsList_1_1 = mutationsList_1.next(); !mutationsList_1_1.done; mutationsList_1_1 = mutationsList_1.next()) {
                    var mutation = mutationsList_1_1.value;
                    _loop_1(mutation);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (mutationsList_1_1 && !mutationsList_1_1.done && (_a = mutationsList_1.return)) _a.call(mutationsList_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
        observer.observe(document.documentElement, {
            childList: true,
            subtree: true,
        });
    };
    ClickTracker.prototype.findATag = function (element, depth) {
        if (depth === void 0) { depth = 0; }
        if (element && depth < 3) {
            if (element.tagName === 'A') {
                return element;
            }
            else {
                depth += 1;
                return this.findATag(element.parentElement, depth);
            }
        }
        return null;
    };
    return ClickTracker;
}(BaseTracker));
export { ClickTracker };
