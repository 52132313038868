/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { __extends } from "tslib";
import { BaseTracker } from './BaseTracker';
import { Event } from '../provider';
var PageLoadTracker = /** @class */ (function (_super) {
    __extends(PageLoadTracker, _super);
    function PageLoadTracker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PageLoadTracker.prototype.init = function () {
        var _this = this;
        this.trackPageLoad = this.trackPageLoad.bind(this);
        if (this.isSupportedEnv()) {
            this.observer = new PerformanceObserver(function () {
                _this.trackPageLoad();
            });
            this.observer.observe({ entryTypes: ['navigation'] });
        }
        if (this.isPageLoaded()) {
            this.trackPageLoad();
        }
    };
    PageLoadTracker.prototype.trackPageLoad = function () {
        if (!this.context.configuration.isTrackPageLoadEvents)
            return;
        var performanceEntries = performance.getEntriesByType('navigation');
        if (performanceEntries && performanceEntries.length > 0) {
            var latestPerformance = performanceEntries[performanceEntries.length - 1];
            var eventAttributes = {};
            for (var key in latestPerformance) {
                var value = latestPerformance[key];
                var valueType = typeof value;
                if (Event.ReservedAttribute.TIMING_ATTRIBUTES.includes(key)) {
                    if (valueType === 'string' || valueType === 'number') {
                        eventAttributes[key] = value;
                    }
                    else if (Array.isArray(value) && value.length > 0) {
                        eventAttributes[key] = JSON.stringify(value);
                    }
                }
            }
            this.provider.record({
                name: Event.PresetEvent.PAGE_LOAD,
                attributes: eventAttributes,
            });
        }
    };
    PageLoadTracker.prototype.isPageLoaded = function () {
        var _a;
        var performanceEntries = performance.getEntriesByType('navigation');
        return ((_a = performanceEntries === null || performanceEntries === void 0 ? void 0 : performanceEntries[0]) === null || _a === void 0 ? void 0 : _a.duration) > 0 || false;
    };
    PageLoadTracker.prototype.isSupportedEnv = function () {
        return !!performance && !!PerformanceObserver;
    };
    return PageLoadTracker;
}(BaseTracker));
export { PageLoadTracker };
