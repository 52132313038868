/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { __extends } from "tslib";
import { BaseTracker } from './BaseTracker';
import { PageViewTracker } from './PageViewTracker';
import { Event } from '../provider';
import { StorageUtil } from '../util/StorageUtil';
var ScrollTracker = /** @class */ (function (_super) {
    __extends(ScrollTracker, _super);
    function ScrollTracker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScrollTracker.prototype.init = function () {
        this.trackScroll = this.trackScroll.bind(this);
        var throttledTrackScroll = this.throttle(this.trackScroll, 100);
        document.addEventListener('scroll', throttledTrackScroll, {
            passive: true,
        });
        var throttledMouseMove = this.throttle(this.onMouseMove, 100);
        document.addEventListener('mousemove', throttledMouseMove, {
            passive: true,
        });
        this.isFirstTime = true;
    };
    ScrollTracker.prototype.enterNewPage = function () {
        this.isFirstTime = true;
    };
    ScrollTracker.prototype.trackScroll = function () {
        var _a;
        PageViewTracker.updateIdleDuration();
        if (!this.context.configuration.isTrackScrollEvents)
            return;
        var scrollY = window.scrollY || document.documentElement.scrollTop;
        var ninetyPercentHeight = document.body.scrollHeight * 0.9;
        var viewedHeight = scrollY + window.innerHeight;
        if (scrollY > 0 && viewedHeight > ninetyPercentHeight && this.isFirstTime) {
            var engagementTime = new Date().getTime() - StorageUtil.getPreviousPageStartTime();
            this.provider.record({
                name: Event.PresetEvent.SCROLL,
                attributes: (_a = {},
                    _a[Event.ReservedAttribute.ENGAGEMENT_TIMESTAMP] = engagementTime,
                    _a),
            });
            this.isFirstTime = false;
        }
    };
    ScrollTracker.prototype.onMouseMove = function () {
        PageViewTracker.updateIdleDuration();
    };
    ScrollTracker.prototype.throttle = function (func, delay) {
        var timeout = null;
        return function () {
            var _this = this;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (!timeout) {
                timeout = setTimeout(function () {
                    func.apply(_this, args);
                    timeout = null;
                }, delay);
            }
        };
    };
    return ScrollTracker;
}(BaseTracker));
export { ScrollTracker };
