/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { LOG_TYPE } from '@aws-amplify/core/lib/Logger';
import { AnalyticsEventBuilder } from './AnalyticsEventBuilder';
import { ClickstreamContext } from './ClickstreamContext';
import { Event } from './Event';
import { EventChecker } from './EventChecker';
import { EventRecorder } from './EventRecorder';
import { BrowserInfo } from '../browser';
import { PageViewTracker, SessionTracker } from '../tracker';
import { ClickTracker } from '../tracker/ClickTracker';
import { PageLoadTracker } from '../tracker/PageLoadTracker';
import { ScrollTracker } from '../tracker/ScrollTracker';
import { PageType, SendMode, } from '../types';
import { StorageUtil } from '../util/StorageUtil';
var logger = new Logger('ClickstreamProvider');
var ClickstreamProvider = /** @class */ (function () {
    function ClickstreamProvider() {
        this.configuration = {
            appId: '',
            endpoint: '',
            sendMode: SendMode.Immediate,
            sendEventsInterval: 5000,
            isTrackPageViewEvents: true,
            isTrackUserEngagementEvents: true,
            isTrackClickEvents: true,
            isTrackSearchEvents: true,
            isTrackScrollEvents: true,
            isTrackPageLoadEvents: false,
            isTrackAppStartEvents: false,
            isTrackAppEndEvents: false,
            pageType: PageType.SPA,
            isLogEvents: false,
            sessionTimeoutDuration: 1800000,
            idleTimeoutDuration: 120000,
            searchKeyWords: [],
            domainList: [],
            globalAttributes: {},
        };
    }
    ClickstreamProvider.prototype.configure = function (configuration) {
        if (configuration.appId === '' || configuration.endpoint === '') {
            logger.error('Please configure your appId and endpoint');
            return configuration;
        }
        Object.assign(this.configuration, configuration);
        this.context = new ClickstreamContext(new BrowserInfo(), this.configuration);
        this.eventRecorder = new EventRecorder(this.context);
        this.globalAttributes = {};
        this.setGlobalAttributes(configuration.globalAttributes);
        this.userAttributes = StorageUtil.getSimpleUserAttributes();
        this.sessionTracker = new SessionTracker(this, this.context);
        this.pageViewTracker = new PageViewTracker(this, this.context);
        this.clickTracker = new ClickTracker(this, this.context);
        this.scrollTracker = new ScrollTracker(this, this.context);
        this.pageLoadTracker = new PageLoadTracker(this, this.context);
        this.sessionTracker.setUp();
        this.pageViewTracker.setUp();
        this.clickTracker.setUp();
        this.scrollTracker.setUp();
        this.pageLoadTracker.setUp();
        if (configuration.sendMode === SendMode.Batch) {
            this.startTimer();
        }
        if (this.context.configuration.isLogEvents) {
            logger.level = LOG_TYPE.DEBUG;
        }
        logger.debug('Initialize the SDK successfully, configuration is:\n' +
            JSON.stringify(this.configuration));
        if (this.eventRecorder.getFailedEventsLength() > 0) {
            this.eventRecorder.haveFailedEvents = true;
            this.eventRecorder.sendFailedEvents();
        }
        return this.configuration;
    };
    ClickstreamProvider.prototype.updateConfigure = function (configuration) {
        Object.assign(this.configuration, configuration);
    };
    ClickstreamProvider.prototype.getCategory = function () {
        return 'Analytics';
    };
    ClickstreamProvider.prototype.getProviderName = function () {
        return 'ClickstreamProvider';
    };
    ClickstreamProvider.prototype.record = function (event) {
        var result = EventChecker.checkEventName(event.name);
        if (result.error_code > 0) {
            logger.error(result.error_message);
            this.recordClickstreamError(result);
            return;
        }
        var resultEvent = this.createEvent(event);
        this.recordEvent(resultEvent, event.isImmediate);
    };
    ClickstreamProvider.prototype.createEvent = function (event, allUserAttributes) {
        if (allUserAttributes === void 0) { allUserAttributes = null; }
        return AnalyticsEventBuilder.createEvent(this.context, event, allUserAttributes === null ? this.userAttributes : allUserAttributes, this.globalAttributes, this.sessionTracker.session);
    };
    ClickstreamProvider.prototype.recordEvent = function (event, isImmediate) {
        if (isImmediate === void 0) { isImmediate = false; }
        this.eventRecorder.record(event, isImmediate);
    };
    ClickstreamProvider.prototype.setUserId = function (userId) {
        var previousUserId = '';
        if (Event.ReservedAttribute.USER_ID in this.userAttributes) {
            previousUserId =
                this.userAttributes[Event.ReservedAttribute.USER_ID].value.toString();
        }
        if (userId === null) {
            delete this.userAttributes[Event.ReservedAttribute.USER_ID];
        }
        else if (userId !== previousUserId) {
            var userInfo = StorageUtil.getUserInfoFromMapping(userId);
            var newUserAttribute = {};
            newUserAttribute[Event.ReservedAttribute.USER_ID] = {
                value: userId,
                set_timestamp: new Date().getTime(),
            };
            newUserAttribute[Event.ReservedAttribute.USER_FIRST_TOUCH_TIMESTAMP] =
                userInfo[Event.ReservedAttribute.USER_FIRST_TOUCH_TIMESTAMP];
            StorageUtil.updateUserAttributes(newUserAttribute);
            this.userAttributes = newUserAttribute;
            this.context.userUniqueId = StorageUtil.getCurrentUserUniqueId();
        }
        this.recordProfileSet(this.userAttributes);
        StorageUtil.updateUserAttributes(this.userAttributes);
    };
    ClickstreamProvider.prototype.setUserAttributes = function (attributes) {
        var timestamp = new Date().getTime();
        var allUserAttributes = StorageUtil.getAllUserAttributes();
        for (var key in attributes) {
            var value = attributes[key];
            if (value === null) {
                delete allUserAttributes[key];
            }
            else {
                var currentNumber = Object.keys(allUserAttributes).length;
                var checkUserAttribute = EventChecker.checkUserAttribute;
                var result = checkUserAttribute(currentNumber, key, value);
                if (result.error_code > 0) {
                    this.recordClickstreamError(result);
                }
                else {
                    allUserAttributes[key] = {
                        value: value,
                        set_timestamp: timestamp,
                    };
                }
            }
        }
        StorageUtil.updateUserAttributes(allUserAttributes);
        this.recordProfileSet(allUserAttributes);
    };
    ClickstreamProvider.prototype.setGlobalAttributes = function (attributes) {
        for (var key in attributes) {
            var value = attributes[key];
            if (value === null) {
                delete this.globalAttributes[key];
            }
            else {
                var currentNumber = Object.keys(this.globalAttributes).length;
                var checkAttributes = EventChecker.checkAttributes;
                var result = checkAttributes(currentNumber, key, value);
                if (result.error_code > 0) {
                    this.recordClickstreamError(result);
                }
                else {
                    this.globalAttributes[key] = value;
                }
            }
        }
    };
    ClickstreamProvider.prototype.recordClickstreamError = function (error) {
        var _a;
        var _b = Event.ReservedAttribute, ERROR_CODE = _b.ERROR_CODE, ERROR_MESSAGE = _b.ERROR_MESSAGE;
        var errorEvent = this.createEvent({
            name: Event.PresetEvent.CLICKSTREAM_ERROR,
            attributes: (_a = {},
                _a[ERROR_CODE] = error.error_code,
                _a[ERROR_MESSAGE] = error.error_message,
                _a),
        });
        this.recordEvent(errorEvent);
    };
    ClickstreamProvider.prototype.recordProfileSet = function (allUserAttributes) {
        var profileSetEvent = this.createEvent({ name: Event.PresetEvent.PROFILE_SET }, allUserAttributes);
        this.recordEvent(profileSetEvent);
    };
    ClickstreamProvider.prototype.startTimer = function () {
        setInterval(this.flushEvents.bind(this, this.eventRecorder), this.configuration.sendEventsInterval);
    };
    ClickstreamProvider.prototype.flushEvents = function (eventRecorder) {
        eventRecorder.flushEvents();
    };
    ClickstreamProvider.prototype.sendEventsInBackground = function (isWindowClosing) {
        if (!(BrowserInfo.isFirefox() && isWindowClosing) &&
            BrowserInfo.isNetworkOnLine()) {
            this.eventRecorder.sendEventsInBackground(isWindowClosing);
        }
    };
    return ClickstreamProvider;
}());
export { ClickstreamProvider };
