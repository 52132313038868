import { __read, __values } from "tslib";
/**
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance
 *  with the License. A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the 'license' file accompanying this file. This file is distributed on an 'AS IS' BASIS, WITHOUT WARRANTIES
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions
 *  and limitations under the License.
 */
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { Event } from './Event';
var logger = new Logger('ClickstreamProvider');
var EventChecker = /** @class */ (function () {
    function EventChecker() {
    }
    EventChecker.checkEventName = function (eventName) {
        var _a = Event.ErrorCode, EVENT_NAME_INVALID = _a.EVENT_NAME_INVALID, EVENT_NAME_LENGTH_EXCEED = _a.EVENT_NAME_LENGTH_EXCEED, NO_ERROR = _a.NO_ERROR;
        var MAX_EVENT_TYPE_LENGTH = Event.Limit.MAX_EVENT_TYPE_LENGTH;
        if (!EventChecker.isValidName(eventName)) {
            return {
                error_code: EVENT_NAME_INVALID,
                error_message: "Event name can only contains uppercase and lowercase letters, " +
                    "underscores, number, and is not start with a number. event name: ".concat(eventName),
            };
        }
        else if (eventName.length > MAX_EVENT_TYPE_LENGTH) {
            return {
                error_code: EVENT_NAME_LENGTH_EXCEED,
                error_message: "Event name is too long, the max event type length is " +
                    "".concat(MAX_EVENT_TYPE_LENGTH, " characters. event name: ").concat(eventName),
            };
        }
        return {
            error_code: NO_ERROR,
        };
    };
    EventChecker.isValidName = function (name) {
        var regex = /^(?![0-9])[0-9a-zA-Z_]+$/;
        return regex.test(name);
    };
    EventChecker.checkAttributes = function (currentNumber, key, value) {
        var _a = Event.Limit, MAX_NUM_OF_ATTRIBUTES = _a.MAX_NUM_OF_ATTRIBUTES, MAX_LENGTH_OF_NAME = _a.MAX_LENGTH_OF_NAME, MAX_LENGTH_OF_VALUE = _a.MAX_LENGTH_OF_VALUE;
        var _b = Event.ErrorCode, NO_ERROR = _b.NO_ERROR, ATTRIBUTE_SIZE_EXCEED = _b.ATTRIBUTE_SIZE_EXCEED, ATTRIBUTE_NAME_INVALID = _b.ATTRIBUTE_NAME_INVALID, ATTRIBUTE_NAME_LENGTH_EXCEED = _b.ATTRIBUTE_NAME_LENGTH_EXCEED, ATTRIBUTE_VALUE_LENGTH_EXCEED = _b.ATTRIBUTE_VALUE_LENGTH_EXCEED;
        var error;
        var errorMsg;
        if (currentNumber >= MAX_NUM_OF_ATTRIBUTES) {
            errorMsg =
                "reached the max number of attributes limit ".concat(MAX_NUM_OF_ATTRIBUTES, ". ") +
                    "and the attribute: ".concat(key, " will not be recorded");
            var errorString = "attribute name: ".concat(key);
            error = {
                error_message: EventChecker.getLimitString(errorString),
                error_code: ATTRIBUTE_SIZE_EXCEED,
            };
        }
        else if (key.length > MAX_LENGTH_OF_NAME) {
            errorMsg =
                "attribute : ".concat(key, ", reached the max length of attributes name ") +
                    "limit(".concat(MAX_LENGTH_OF_NAME, "). current length is: (").concat(key.length, ") ") +
                    "and the attribute will not be recorded";
            var errorString = "attribute name length is: (".concat(key.length, ") name is: ").concat(key);
            error = {
                error_message: EventChecker.getLimitString(errorString),
                error_code: ATTRIBUTE_NAME_LENGTH_EXCEED,
            };
        }
        else if (!EventChecker.isValidName(key)) {
            errorMsg =
                "attribute : ".concat(key, ", was not valid, attribute name can only ") +
                    "contains uppercase and lowercase letters, underscores, number, and is not " +
                    "start with a number, so the attribute will not be recorded";
            error = {
                error_message: EventChecker.getLimitString(key),
                error_code: ATTRIBUTE_NAME_INVALID,
            };
        }
        else if (String(value).length > MAX_LENGTH_OF_VALUE) {
            errorMsg =
                "attribute : ".concat(key, ", reached the max length of attributes value limit ") +
                    "(".concat(MAX_LENGTH_OF_VALUE, "). current length is: (").concat(String(value).length, "). ") +
                    "and the attribute will not be recorded, attribute value: ".concat(value);
            var errorString = "attribute name: ".concat(key, ", attribute value: ").concat(value);
            error = {
                error_message: EventChecker.getLimitString(errorString),
                error_code: ATTRIBUTE_VALUE_LENGTH_EXCEED,
            };
        }
        if (error) {
            logger.warn(errorMsg);
            return error;
        }
        return {
            error_code: NO_ERROR,
        };
    };
    EventChecker.getLimitString = function (str) {
        return str.substring(0, Event.Limit.MAX_LENGTH_OF_ERROR_VALUE);
    };
    EventChecker.checkUserAttribute = function (currentNumber, key, value) {
        var _a = Event.Limit, MAX_NUM_OF_USER_ATTRIBUTES = _a.MAX_NUM_OF_USER_ATTRIBUTES, MAX_LENGTH_OF_NAME = _a.MAX_LENGTH_OF_NAME, MAX_LENGTH_OF_USER_VALUE = _a.MAX_LENGTH_OF_USER_VALUE;
        var _b = Event.ErrorCode, NO_ERROR = _b.NO_ERROR, USER_ATTRIBUTE_SIZE_EXCEED = _b.USER_ATTRIBUTE_SIZE_EXCEED, USER_ATTRIBUTE_NAME_LENGTH_EXCEED = _b.USER_ATTRIBUTE_NAME_LENGTH_EXCEED, USER_ATTRIBUTE_NAME_INVALID = _b.USER_ATTRIBUTE_NAME_INVALID, USER_ATTRIBUTE_VALUE_LENGTH_EXCEED = _b.USER_ATTRIBUTE_VALUE_LENGTH_EXCEED;
        var error;
        var errorMsg;
        if (currentNumber >= MAX_NUM_OF_USER_ATTRIBUTES) {
            errorMsg =
                "reached the max number of user attributes limit (".concat(MAX_NUM_OF_USER_ATTRIBUTES, "). ") +
                    "and the user attribute: ".concat(key, " will not be recorded");
            var errorString = "attribute name:".concat(key);
            error = {
                error_message: EventChecker.getLimitString(errorString),
                error_code: USER_ATTRIBUTE_SIZE_EXCEED,
            };
        }
        else if (key.length > MAX_LENGTH_OF_NAME) {
            errorMsg =
                "user attribute : ".concat(key, ", reached the max length of attributes name limit ") +
                    "(".concat(MAX_LENGTH_OF_NAME, "). current length is: (").concat(key.length, ") ") +
                    "and the attribute will not be recorded";
            var errorString = "user attribute name length is: (".concat(key.length, ") name is: ").concat(key);
            error = {
                error_message: EventChecker.getLimitString(errorString),
                error_code: USER_ATTRIBUTE_NAME_LENGTH_EXCEED,
            };
        }
        else if (!EventChecker.isValidName(key)) {
            errorMsg =
                "user attribute : ".concat(key, ", was not valid, user attribute name can only ") +
                    "contains uppercase and lowercase letters, underscores, number, and is not " +
                    "start with a number. so the attribute will not be recorded";
            error = {
                error_message: EventChecker.getLimitString(key),
                error_code: USER_ATTRIBUTE_NAME_INVALID,
            };
        }
        else if (String(value).length > MAX_LENGTH_OF_USER_VALUE) {
            errorMsg =
                "user attribute : ".concat(key, ", reached the max length of attributes value limit ") +
                    "(".concat(MAX_LENGTH_OF_USER_VALUE, "). current length is: (").concat(String(value).length, "). ") +
                    "and the attribute will not be recorded, attribute value: ".concat(value);
            var errorString = "attribute name: ".concat(key, ", attribute value: ").concat(value);
            error = {
                error_message: EventChecker.getLimitString(errorString),
                error_code: USER_ATTRIBUTE_VALUE_LENGTH_EXCEED,
            };
        }
        if (error) {
            logger.warn(errorMsg);
            return error;
        }
        return {
            error_code: NO_ERROR,
        };
    };
    EventChecker.checkItems = function (currentNumber, item) {
        var e_1, _a;
        if (EventChecker.itemKeySet === undefined) {
            EventChecker.initItemKeySet();
        }
        var _b = Event.Limit, MAX_NUM_OF_ITEMS = _b.MAX_NUM_OF_ITEMS, MAX_LENGTH_OF_ITEM_VALUE = _b.MAX_LENGTH_OF_ITEM_VALUE, MAX_NUM_OF_CUSTOM_ITEM_ATTRIBUTE = _b.MAX_NUM_OF_CUSTOM_ITEM_ATTRIBUTE, MAX_LENGTH_OF_NAME = _b.MAX_LENGTH_OF_NAME;
        var _c = Event.ErrorCode, NO_ERROR = _c.NO_ERROR, ITEM_SIZE_EXCEED = _c.ITEM_SIZE_EXCEED, ITEM_VALUE_LENGTH_EXCEED = _c.ITEM_VALUE_LENGTH_EXCEED, ITEM_CUSTOM_ATTRIBUTE_SIZE_EXCEED = _c.ITEM_CUSTOM_ATTRIBUTE_SIZE_EXCEED, ITEM_CUSTOM_ATTRIBUTE_KEY_LENGTH_EXCEED = _c.ITEM_CUSTOM_ATTRIBUTE_KEY_LENGTH_EXCEED, ITEM_CUSTOM_ATTRIBUTE_KEY_INVALID = _c.ITEM_CUSTOM_ATTRIBUTE_KEY_INVALID;
        var itemKey = JSON.stringify(item);
        if (currentNumber >= MAX_NUM_OF_ITEMS) {
            var errorMsg_1 = "reached the max number of items limit ".concat(MAX_NUM_OF_ITEMS, ". ") +
                "and the item: ".concat(itemKey, " will not be recorded");
            logger.warn(errorMsg_1);
            var errorString = "item: ".concat(itemKey);
            return {
                error_message: EventChecker.getLimitString(errorString),
                error_code: ITEM_SIZE_EXCEED,
            };
        }
        var customKeyNumber = 0;
        var errorMsg;
        var error;
        try {
            for (var _d = __values(Object.entries(item)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var _f = __read(_e.value, 2), key = _f[0], value = _f[1];
                var valueStr = String(value);
                if (!EventChecker.itemKeySet.has(key)) {
                    customKeyNumber += 1;
                    if (customKeyNumber > MAX_NUM_OF_CUSTOM_ITEM_ATTRIBUTE) {
                        errorMsg =
                            "reached the max number of custom item attributes limit (".concat(MAX_NUM_OF_CUSTOM_ITEM_ATTRIBUTE) +
                                "). and the item: ".concat(itemKey, " will not be recorded");
                        var errorString = "item attribute key: ".concat(key);
                        error = {
                            error_message: EventChecker.getLimitString(errorString),
                            error_code: ITEM_CUSTOM_ATTRIBUTE_SIZE_EXCEED,
                        };
                    }
                    else if (key.length > Event.Limit.MAX_LENGTH_OF_NAME) {
                        errorMsg =
                            "item attribute key: ".concat(key, " , reached the max length of item attributes key limit(") +
                                "".concat(MAX_LENGTH_OF_NAME, "). current length is:(").concat(key.length, ") and the item: ").concat(itemKey, " will not be recorded");
                        var errorString = 'item attribute key: ' + key;
                        error = {
                            error_message: EventChecker.getLimitString(errorString),
                            error_code: ITEM_CUSTOM_ATTRIBUTE_KEY_LENGTH_EXCEED,
                        };
                    }
                    else if (!EventChecker.isValidName(key)) {
                        errorMsg =
                            "item attribute key: ".concat(key, ", was not valid, item attribute key can only contains") +
                                ' uppercase and lowercase letters, underscores, number, and is not start with a number.' +
                                " so the item: ".concat(itemKey, " will not be recorded");
                        error = {
                            error_message: EventChecker.getLimitString(key),
                            error_code: ITEM_CUSTOM_ATTRIBUTE_KEY_INVALID,
                        };
                    }
                }
                if (!error && valueStr.length > MAX_LENGTH_OF_ITEM_VALUE) {
                    errorMsg =
                        "item attribute : ".concat(key, ", reached the max length of item attribute value limit ") +
                            "(".concat(MAX_LENGTH_OF_ITEM_VALUE, "). current length is: (").concat(valueStr.length, "). ") +
                            "and the item: ".concat(itemKey, " will not be recorded");
                    var errorString = "item attribute name: ".concat(key, ", item attribute value: ").concat(valueStr);
                    error = {
                        error_message: EventChecker.getLimitString(errorString),
                        error_code: ITEM_VALUE_LENGTH_EXCEED,
                    };
                }
                if (error) {
                    logger.warn(errorMsg);
                    return error;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return {
            error_code: NO_ERROR,
        };
    };
    EventChecker.initItemKeySet = function () {
        EventChecker.itemKeySet = new Set();
        EventChecker.itemKeySet.add('id');
        EventChecker.itemKeySet.add('name');
        EventChecker.itemKeySet.add('location_id');
        EventChecker.itemKeySet.add('brand');
        EventChecker.itemKeySet.add('currency');
        EventChecker.itemKeySet.add('price');
        EventChecker.itemKeySet.add('quantity');
        EventChecker.itemKeySet.add('creative_name');
        EventChecker.itemKeySet.add('creative_slot');
        EventChecker.itemKeySet.add('category');
        EventChecker.itemKeySet.add('category2');
        EventChecker.itemKeySet.add('category3');
        EventChecker.itemKeySet.add('category4');
        EventChecker.itemKeySet.add('category5');
    };
    return EventChecker;
}());
export { EventChecker };
