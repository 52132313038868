import { StorageUtil } from '../util/StorageUtil';
var Session = /** @class */ (function () {
    function Session(sessionId, sessionIndex, startTime, pauseTime) {
        if (pauseTime === void 0) { pauseTime = undefined; }
        this.isRecorded = false;
        this.sessionId = sessionId;
        this.sessionIndex = sessionIndex;
        this.startTime = startTime;
        this.pauseTime = pauseTime;
    }
    Session.createSession = function (uniqueId, sessionIndex) {
        return new Session(this.getSessionId(uniqueId), sessionIndex, new Date().getTime());
    };
    Session.prototype.isNewSession = function () {
        return this.pauseTime === undefined && !this.isRecorded;
    };
    Session.prototype.getDuration = function () {
        return new Date().getTime() - this.startTime;
    };
    Session.prototype.pause = function () {
        this.pauseTime = new Date().getTime();
    };
    Session.getCurrentSession = function (context, previousSession) {
        if (previousSession === void 0) { previousSession = null; }
        var session = previousSession;
        if (previousSession === null) {
            session = StorageUtil.getSession();
        }
        if (session !== null) {
            if (session.pauseTime === undefined ||
                new Date().getTime() - session.pauseTime <
                    context.configuration.sessionTimeoutDuration) {
                return session;
            }
            else {
                return Session.createSession(context.userUniqueId, session.sessionIndex + 1);
            }
        }
        else {
            return Session.createSession(context.userUniqueId, 1);
        }
    };
    Session.getSessionId = function (uniqueId) {
        var uniqueIdKey = uniqueId.slice(-Constants.maxUniqueIdLength);
        return "".concat(uniqueIdKey, "-").concat(this.getFormatTime());
    };
    Session.getFormatTime = function () {
        var now = new Date();
        var year = now.getUTCFullYear().toString().padStart(4, '0');
        var month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
        var day = now.getUTCDate().toString().padStart(2, '0');
        var hours = now.getUTCHours().toString().padStart(2, '0');
        var minutes = now.getUTCMinutes().toString().padStart(2, '0');
        var seconds = now.getUTCSeconds().toString().padStart(2, '0');
        var milliseconds = now.getUTCMilliseconds().toString().padStart(3, '0');
        return "".concat(year).concat(month).concat(day, "-").concat(hours).concat(minutes).concat(seconds).concat(milliseconds);
    };
    return Session;
}());
export { Session };
var Constants;
(function (Constants) {
    Constants[Constants["maxUniqueIdLength"] = 8] = "maxUniqueIdLength";
})(Constants || (Constants = {}));
